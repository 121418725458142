import STUDENTS_DATA from '@/src/router/views/student/students.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import store from '@src/state/store'
export const rightBarStats = () => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
    },
  ]
  return stats
}

export const studentsGraphChart = (stats) => {
  let campus = store?.state?.layout?.currentCampusScope
  let section = store?.state?.layout?.currentSectionScope
  let BAR_LABELS =
    !campus && !section
      ? STUDENTS_DATA.INSTITUTE_BAR_LABELS
      : campus && !section
      ? STUDENTS_DATA.CAMPUS_BAR_LABELS
      : STUDENTS_DATA.SECTION_BAR_LABELS
  let BAR_TITLE =
    !campus && !section
      ? STUDENTS_DATA.INSTITUTE_BAR_TITLE
      : campus && !section
      ? STUDENTS_DATA.CAMPUS_BAR_TITLE
      : STUDENTS_DATA.SECTION_BAR_TITLE
  let INSTITUTE_BAR_GRAPH_VALUE = [
    stats?.total_students,
    stats?.associated_students_count,
    stats?.unassociated_students_count,
    stats?.profiles_completion_count,
  ]

  let CAMPUS_BAR_GRAPH_VALUE = [
    stats?.total_students,
    stats?.associated_students_count,
    stats?.unassociated_students_count,
    stats?.profiles_completion_count,
    stats?.section_assigned_count,
    stats?.section_unassigned_count,
  ]

  let SECTION_BAR_GRAPH_VALUE = [
    stats?.total_students,
    stats?.associated_students_count,
    stats?.unassociated_students_count,
    stats?.profiles_completion_count,
    stats?.promote_students_count,
    stats?.passout_students_count,
  ]

  let PIE_GRAPH_VALUE = [
    stats?.total_students - stats?.login_enabled_count,
    stats?.login_enabled_count,
  ]
  let BAR_GRAPH_VALUE
  BAR_GRAPH_VALUE = INSTITUTE_BAR_GRAPH_VALUE
  if (campus) {
    BAR_GRAPH_VALUE = CAMPUS_BAR_GRAPH_VALUE
  }
  if (section) {
    BAR_GRAPH_VALUE = SECTION_BAR_GRAPH_VALUE
  }

  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      BAR_LABELS,
      BAR_GRAPH_VALUE,
      BAR_TITLE,
      STUDENTS_DATA.BAR_LABELS_STRING,
    ),
    GRAPH_CONSTANTS.PIE_CHART(
      STUDENTS_DATA.PIE_LABELS,
      PIE_GRAPH_VALUE,
      STUDENTS_DATA.PIE_BACKGROUND_COLOR,
      STUDENTS_DATA.PIE_TITLE,
    ),
  ]
  return chartData
}
