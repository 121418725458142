<template>
  <ValidationObserver @submit="editExistingGuardian">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Edit Guardian</span>
      </template>
      <UserDetailedView
        :user="currentUser"
        :additional-info="additionalInfo"
        class="boder border-b border-primary-purple-100"
      />
      <div class="pt-4">
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="selectedGuardian"
            title="SELECT_GUARDIAN_TO_EDIT"
            :label="'full_name'"
            :options="guardiansList"
            class="text-text-color font-inter text-base font-normal"
            :image="true"
            :filter="{
              option: FILTER_KEYS.EQUAL,
              key: 'student_user_id',
            }"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="getGuardians"
          />
        </InputFieldWrapper>

        <div v-if="selectedGuardian">
          <ValidationObserver :key="1">
            <InputFieldWrapper>
              <UIInputBox
                v-model.trim="guardian.guardian_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                label="First Name"
                placeholder="Enter First Name"
                class="flex-1"
                rules="required|alpha_spaces"
              />
              <UIInputBox
                v-model.trim="guardian.guardian_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                class="flex-1"
                placeholder="Enter Last Name"
                rules="required|alpha_spaces"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                :key="forceRerender"
                v-model="guardian.guardian_info.gender"
                title="Gender"
                :options="genderList"
                class="flex-1"
              />
              <UIInputBox
                id="img-btn"
                ref="imgUpload"
                v-model="guardianImage"
                title="Photo"
                placeholder="Photo Upload"
                type="file"
                :preview-image="previewImage"
                :rules="imageRules"
                class="flex-1"
                @preview="preview"
                @clearValue="clearValue"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UIInputBox
                v-model="guardian.guardian_info.email"
                type="email"
                name="Email"
                title="Email"
                label="Email"
                placeholder="Email"
                class="flex-1"
                :rules="isGuardianActivated && !guardianPhoneNumber ? 'required|email' : 'email'"
              />
              <PhoneNumber
                :key="forceRerender"
                v-model="guardianPhoneNumber"
                title="Phone Number"
                :rules="
                  isGuardianActivated && !guardian.guardian_info.email
                    ? 'required|validNumber'
                    : 'validNumber'
                "
                class="flex-1"
                @update="updateGuardianNumber"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDatePicker
                v-model="guardian.profile_info.date_of_birth"
                title="DOB"
                class="flex-1"
              />
              <UIInputBox
                v-model.trim="guardian.profile_info.religion"
                type="text"
                name="Religion"
                title="Religion"
                label="Religion"
                placeholder="Religion"
                class="flex-1"
                rules="alpha_spaces"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                :key="forceRerender"
                v-model="guardian.profile_info.blood_group"
                title="Blood Group"
                class="flex-1"
                :options="bloodGroupList"
              />
              <UIInputBox
                v-model="guardian.profile_info.address"
                type="text"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                :key="forceRerender"
                v-model="guardian.identity_info.type"
                title="Identity Type"
                :options="IDENTITY_TYPE_OPTIONS"
                label="title"
                reduce="value"
                class="flex-1"
                @change="resetIdentityInfo"
              />
            </InputFieldWrapper>

            <InputFieldWrapper v-if="guardian.identity_info.type">
              <UIInputBox
                v-model="guardian.identity_info.value"
                type="text"
                name="Identity Number"
                title="Identity Number"
                label="Identity Number"
                placeholder="Enter Identity No"
                class="flex-1"
                rules="required|numeric"
              />
              <UIInputBox
                v-model="guardian.identity_info.country"
                type="text"
                name="Country"
                title="ID_COUNTRY"
                label="Country"
                placeholder="Enter Country"
                class="flex-1"
                rules="required|alpha"
              />
            </InputFieldWrapper>
          </ValidationObserver>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="!selectedGuardian || isLoading">
            <template v-if="isLoading">
              <Loader></Loader>
            </template>
            <template v-else>
              <span>Update</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import { buildWhereQuery } from '@src/utils/filters/index.js'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'

import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UserDetailedView from '@src/components/userDetailedView.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import UIInputBox from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { IDENTITY_TYPE_OPTIONS } from '@src/constants/general-constants.js'
import { transformGuardianData } from '@/src/utils/settings/tenant-user.util'
import filterMixins from '@/src/mixins/filter-mixins.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    PhoneNumber,
    UIInputBox,
    UiSingleSelect,
    UiModalContainer,
    Loader,
    ValidationObserver,
    UserDetailedView,
    UiDatePicker,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    modal: { type: Boolean, default: false },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      FILTER_KEYS,
      IDENTITY_TYPE_OPTIONS,
      studentList: [],
      guardiansList: [],
      studentId: '',
      selectedGuardian: '',
      relation: '',
      showModal: false,
      isLoading: false,
      edit: false,
      additionalInfo: { name: 'STD_NAME', additionalTitle: 'TOTAL_GUARDIAN', additionalValue: '0' },
      guardian: {
        guardian_info: {
          first_name: '',
          last_name: '',
          email: '',
          gender: '',
          user_type: 'guardian',
          profession: '',
          phone: '',
          address: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          height: '',
          weight: '',
          image: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
      },
      forceRerender: 0,
      genderList: ['male', 'female'],
      bloodGroupList: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      isGuardianActivated: true,
      previewImage: null,
      guardianImage: null,
      guardianPhoneNumber: '',
    }
  },
  computed: {
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },
  watch: {
    selectedGuardian: {
      handler() {
        this.forceRerender++
        this.setGuardianData()
      },
    },
  },

  created() {
    this.studentId = this.currentUser?.id
    this.getGuardians()
  },
  methods: {
    resetIdentityInfo() {
      this.guardian.identity_info.country = ''
      this.guardian.identity_info.value = ''
    },
    setGuardianData() {
      this.guardian = transformGuardianData(this.selectedGuardian)
      this.previewImage = {}
      this.previewImage.src = this.selectedGuardian?.image || (this.previewImage = null)
      this.guardianPhoneNumber = this.selectedGuardian?.phone
      this.isGuardianActivated = !!this.selectedGuardian?.login_activated_at
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    updateGuardianNumber(payload) {
      this.guardian.guardian_info.phone = payload.formattedNumber
    },
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.guardianImage = image
    },

    editExistingGuardian() {
      this.isLoading = true
      const data = new FormData()
      data.append('first_name', this.guardian?.guardian_info?.first_name || '')
      data.append('last_name', this.guardian?.guardian_info?.last_name || '')
      data.append('gender', this.guardian?.guardian_info?.gender || '')
      data.append('email', this.guardian?.guardian_info?.email || '')
      data.append('phone', this.guardian?.guardian_info?.phone || '')
      data.append('blood_group', this.guardian?.profile_info?.blood_group || '')
      data.append('date_of_birth', this.guardian?.profile_info?.date_of_birth || '')
      data.append('religion', this.guardian?.profile_info?.religion || '')
      data.append('type', this.guardian?.identity_info?.type || '')
      data.append('value', this.guardian?.identity_info?.value || '')
      data.append('country', this.guardian?.identity_info?.country || '')
      data.append('address', this.guardian?.profile_info?.address || '')
      if (this.guardianImage) data.append('profile_image', this.guardianImage)

      const payload = {
        guardian: data,
        id: this.selectedGuardian?.id,
      }
      this.editGuardian(payload)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getGuardians(query = '') {
      let filtersPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'student_user_id', this.studentId),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getGuardiansWithFilter(filtersPayload).then((res) => {
        this.guardiansList = res?.records
        this.guardiansList?.forEach((guardian) => {
          guardian.guardian_id = guardian?.guardian_info?.id
          fullName(guardian)
        })
        this.showModal = this.modal
        if (query === '') this.additionalInfo.additionalValue = res?.meta.total_records
      })
    },
    ...mapActions('guardian', ['getGuardiansWithFilter', 'editGuardian']),
  },
}
</script>
