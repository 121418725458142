<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    v-if="modal"
    footer-classes="flex justify-end"
    :modal-show="modal"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Student Bulk Add</span>
    </template>
    <template v-slot>
      <div class="mb-5 mx-5">
        <div
          v-if="studentList.length"
          class="rounded-lg border border-primary-grey items-center mt-6 xl:max-w-screen-xl lg:max-w-screen-md md:max-w-screen-sm sm:max-w-xl max-w-xs overflow-x-scroll"
        >
          <table class="w-full table-auto whitespace-nowrap mb-0.5 border-b border-primary-grey">
            <thead>
              <tr class="text-left">
                <th
                  v-for="head in bulkTableHeader"
                  :key="head"
                  class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                  >
                    {{ head }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(std, index) in studentList"
                :key="index"
                class="border-t border-primary-grey text-left pb-5"
              >
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <UiInput
                      :key="`first_name${index}`"
                      v-model="studentList[index]['first name']"
                      placeholder="First Name"
                      class="min-w-2"
                      title="First Name"
                      :hide-title="true"
                      type="text"
                      rules="required|alpha_spaces"
                    />
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <UiInput
                      :key="`last_name${index}`"
                      v-model="studentList[index]['last name']"
                      placeholder="Last Name"
                      class="min-w-2"
                      title="Last Name"
                      :hide-title="true"
                      type="text"
                      rules="required|alpha_spaces"
                    />
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <!-- <ValidationObserver tag="div"> -->
                    <UiInput
                      :key="`email${index}`"
                      v-model="studentList[index]['email']"
                      placeholder="email"
                      class="min-w-2"
                      :hide-title="true"
                      title="email"
                      type="email"
                      rules="email"
                    />
                    <!-- </ValidationObserver> -->
                  </span>
                </td>

                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 p-6">
                  <PhoneNumber
                    v-model="studentList[index]['phone']"
                    class="min-w-3"
                    rules="validNumber"
                  />
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 pt-5">
                  <UiInput
                    :key="`guardian_first_name${index}`"
                    v-model="studentList[index]['guardian first name']"
                    placeholder="GRD_FIRST_NAME"
                    class="min-w-2"
                    :hide-title="true"
                    title="GRD_FIRST_NAME"
                    type="text"
                  />
                </td>

                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <UiInput
                      :key="`guardian_last_name${index}`"
                      v-model="studentList[index]['guardian last name']"
                      placeholder="GRD_LAST_NAME"
                      class="min-w-2"
                      :hide-title="true"
                      title="GRD_LAST_NAME"
                      type="text"
                    />
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <UiInput
                      :key="`relation${index}`"
                      v-model="studentList[index]['relation']"
                      placeholder="RELATION"
                      class="min-w-2"
                      :hide-title="true"
                      title="text"
                      type="textl"
                    />
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <PhoneNumber
                    :key="`guardian_phone${index}`"
                    v-model="studentList[index]['guardian phone']"
                    class="min-w-3"
                    rules="validNumber"
                  />
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <span
                    class="min-w-min inline-block font-roboto font-normal text-text-color text-sm pt-5"
                  >
                    <!-- <ValidationObserver tag="div"> -->
                    <UiInput
                      :key="`guardian_email${index}`"
                      v-model="studentList[index]['guardian email']"
                      placeholder="email"
                      class="min-w-2"
                      :hide-title="true"
                      title="email"
                      type="email"
                      rules="email"
                    />
                    <!-- </ValidationObserver> -->
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                  <div @click="removeRecord(index)">
                    <icon
                      class="icon cursor-pointer mx-auto"
                      color="text-color-danger"
                      icon="crossCircle"
                      height="20"
                      width="20"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        class="ltr:mr-3 rtl:ml-3 focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        :disabled="csvUpload"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        :disabled="csvUpload"
        @click.prevent="handleSubmit(formCheck(invalid))"
      >
        <template v-if="csvUpload">
          <Loader />
        </template>
        <template v-else>
          <span>Save</span>
        </template>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import icon from '@src/components/icons/icon.vue'

export default {
  components: {
    // ValidationObserver,
    PhoneNumber,
    UiInput,
    UiModalContainer,
    Loader,
    icon,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    bulkStudentsList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggle'],
  data() {
    return {
      studentList: null,
      csvUpload: false,
      bulkTableHeader: [
        'First Name',
        'Last Name',
        'Email',
        'Phone',
        'Guardian First Name',
        'Guardian Last Name',
        'Relation',
        'Guardian Phone',
        'Guardian Email',
        'Remove',
      ],
    }
  },
  created() {
    this.studentList = objectDeepCopy(this.bulkStudentsList)
  },
  methods: {
    formCheck(invalid) {
      if (invalid) return
      this.csvUpload = true
      this.seedStudent(this.studentList)
        .then((data) => {
          data.response.forEach((error) => {
            this.$store.commit('toast/NEW', { message: error, type: 'error', duration: 10000 })
          })
          this.$emit('toggle', 'fetch')
        })
        .catch((error) => {
          this.$store.commit('toast/NEW', { message: error, type: 'error' })
        })
        .finally(() => {
          this.csvUpload = false
        })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    removeRecord(position) {
      this.studentList.splice(position, 1)
    },
    ...mapActions('student', ['seedStudent']),
  },
}
</script>
