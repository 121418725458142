<template>
  <ValidationObserver @submit="passedOut">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :disable-position-center="true"
      :modal-show="modal"
      :modal-width="60"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Mark Student Passed Out</span>
      </template>
      <div>
        <div class="text-xs md:text-base">
          <div class="flex lg:gap-3 mt-3 flex-col lg:flex-row">
            <div class="flex flex-1 gap-2">
              <div class="font-bold">
                <span v-i18n="title">First Name</span>
                <span>:</span>
              </div>
              <div class="text-primary-grey-light capitalize">
                {{ student.student_info.first_name }}
              </div>
            </div>
            <div class="flex flex-1 gap-2">
              <div class="font-bold">
                <span v-i18n="title">Last Name</span>
                <span>:</span>
              </div>
              <div class="text-primary-grey-light capitalize">
                {{ student.student_info.last_name }}
              </div>
            </div>
          </div>
          <div class="flex lg:gap-3 flex-col lg:flex-row flex-wrap mt-5">
            <div class="flex flex-1 gap-2">
              <div class="font-bold">
                <span v-i18n="title">User Name</span>
                <span>:</span>
              </div>
              <div class="text-primary-grey-light">{{ student.student_info.username }}</div>
            </div>
            <div class="flex flex-1 gap-2">
              <div class="font-bold">
                <span v-i18n="title">Phone Number</span>
                <span>:</span>
              </div>
              <div class="text-primary-grey-light">{{ studentNumber }}</div>
            </div>
          </div>
          <div class="flex lg:gap-3 gap-2 flex-col lg:flex-row flex-wrap mt-5">
            <div class="flex flex-1 gap-2">
              <div class="font-bold">
                <span v-i18n="title">Email</span>
                <span>:</span>
              </div>
              <div class="text-primary-grey-light">{{ student.student_info.email }}</div>
            </div>
          </div>
        </div>
        <div class="px-6 pt-6">
          <div v-i18n="title" class="text-center text-2xl text-label-text">
            PASS_OUT_CONFIRMATION
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div v-if="currentStat > 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">No</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader></Loader>
            </template>
            <template v-else>
              <span>Yes</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
/* 3rd party compononent */
import { transformStudentData } from '@src/utils/settings/tenant-user.util.js'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'

/* VUEX */
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    UIButton,
    Loader,
  },
  props: {
    modal: { type: Boolean },
    currentStudent: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      title: 'title',
      studentNumber: 'N/A',
      confirmedPassword: '',
      options: ['Select'],
      dashboard: 'dashboard',
      student: {
        student_info: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          gender: '',
          username: '',
          user_type: 'student',
          registration_no: '',
          roll_no: '',
          password: '',
          login_activated_at: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          address: '',
        },
        guardian_info: {},
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
        academic_info: {
          campus_id: '',
          section_id: '',
          year: '',
        },
      },
    }
  },
  computed: {
    ...mapState('layout', ['isLoading', 'currentCampusScope', 'currentSectionScope', 'classList']),
  },
  mounted() {
    if (this.currentStudent) this.setStudentData()
    if (!this.student.student_info.username) {
      this.student.student_info.username = 'N/A'
    }
  },

  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle', 'cancel')
          break
      }
    },

    passedOut() {
      this.$store.commit('layout/IS_LOADING', true)
      this.passedStudentOut(this.currentStudent.id)
        .then(() => {
          this.$emit('toggle', 'fetch')
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    setStudentData() {
      this.student = transformStudentData(this.currentStudent)
      this.studentNumber = this.currentStudent?.phone || 'N/A'
      this.student.student_info.login_activated_at = this.currentStudent.login_activated_at
    },
    ...mapActions('student', ['passedStudentOut']),
  },
}
</script>

<style lang="scss" scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 7px 14px var(--primary-green-dark);
  }
  .step-info {
    color: var(--text-color);
  }
}
</style>
