<template>
  <ValidationObserver @submit="enrollStudent">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Student Enrollment</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="enrollmentData.campus_id"
            title="CAMPUSES"
            label="title"
            :options="campuses"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            :disabled="!!currentCampusScope"
            @search="getCampus"
            @change="getClasses(enrollmentData.campus_id)"
            @deSelect="getClasses(enrollmentData.campus_id)"
          />
          <UiSingleSelect
            v-model="enrollmentData.class_id"
            title="Class"
            :options="usersClassList"
            label="title"
            reduce="id"
            :search-through-api="true"
            class="flex-1"
            :disabled="!!currentClassScope || !enrollmentData.campus_id"
            @change="setSection(enrollmentData.class_id)"
            @search="getClass"
            @deSelect="setSection(enrollmentData.class_id)"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="enrollmentData.section_id"
            title="SECTS"
            label="title"
            class="flex-1"
            reduce="id"
            :search-through-api="true"
            :rules="sectionRules"
            :disabled="currentSectionScope || !enrollmentData.class_id"
            :options="usersSectionList"
            @search="getSection"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <span>Save</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState } from 'vuex'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      sectionsList: [],
      enrollmentData: {
        campus_id: '',
        class_id: '',
        section_id: '',
        student_id: '',
      },
    }
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentClassScope',
      'currentSectionScope',
      'campuses',
      'isLoading',
    ]),
    ...mapState('classes', ['usersClassList']),
    ...mapState('section', ['usersSectionList']),
    sectionRules() {
      return this.currentClassScope || this.enrollmentData.class_id ? 'required' : ''
    },
  },

  created() {
    this.enrollmentData.student_id = this.currentUser?.id

    if (this.currentCampusScope) {
      this.enrollmentData.campus_id = this.currentCampusScope?.id
      this.getClasses(this.enrollmentData.campus_id)
    }

    if (this.currentClassScope) {
      this.enrollmentData.class_id = this.currentClassScope?.id
      this.setSection(this.enrollmentData.class_id)
    }
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    /**
     * Get Campus Section from server
     * Set response in state
     */

    /**
     * Enroll student in campus and section
     * @param {invalid} validation
     */
    async enrollStudent() {
      try {
        this.$store.commit('layout/IS_LOADING', true)
        removeEmptyKeysFromObject(this.enrollmentData)
        await this.studentEnrollment(this.enrollmentData).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: 'Successfully Enrolled',
          })
        })
        this.$emit('toggle', 'fetch')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    getClass(query = '') {
      if (query) this.getClasses(this.enrollmentData?.campus_id, query)
    },
    getClasses(id, query) {
      this.enrollmentData.class_id = ''
      this.enrollmentData.section_id = ''
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getUserClasses(payload)
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    getSection(query = '') {
      if (query) this.setSection(this.enrollmentData?.class_id, query)
    },
    setSection(id, query = '') {
      this.enrollmentData.section_id = ''
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload)
    },
    ...mapActions('student', ['studentEnrollment']),
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('classes', ['getUserClasses']),
  },
}
</script>
