<template>
  <div class="flex gap-5 xs:gap-1 items-center">
    <div v-if="heading" :class="headingStyle">{{ heading }}</div>
    <div class="panel-body flex gap-2 items-center">
      <p v-if="!isStatusHide" class="text-primary-purple-600">{{ checkbox }}</p>
      <label class="switch relative inline-block w-11 h-6">
        <input v-model="checkbox" :disabled="disabled" type="checkbox" @click="toggleCheckbox" />
        <div
          class="slider round absolute left-0 right-0 top-0 bottom-0 cursor-pointer"
          :class="[{ 'slider-disable-bg': !checkbox }, checkboxColorClass]"
          :style="customStyle"
        ></div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    headingStyle: {
      type: String,
      default: 'text-base leading-6 font-normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    isStatusHide: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    checkboxColorClass: {
      type: String,
      default: 'bg-primary-purple-600',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      checkbox: false,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.checkbox = value
      },
    },
  },
  methods: {
    toggleCheckbox() {
      this.checkbox = !this.checkbox
      this.$emit('update:modelValue', this.checkbox)
    },
  },
}
</script>

<style lang="scss" scoped>
.switch input {
  display: none;
}
.slider {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider::before {
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:focus + .slider {
  box-shadow: 0 0 0 4px var(--primary-purple-100);
}

input:checked + .slider::before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 12px;
}

.slider.round::before {
  border-radius: 50%;
}
</style>

<style lang="scss">
.slider-disable-bg {
  background-color: var(--primary-gray-200);
}
</style>
