<template>
  <ValidationObserver @submit="associateGuardian">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Association</span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <UiSingleSelect
              v-if="showStudents"
              v-model="studentId"
              title="STUDENT"
              label="full_name"
              :options="studentList"
              class="flex-1"
              reduce="student_id"
              :image="true"
              rules="required"
              :search-through-api="true"
              @search="getStudents"
            />
            <UiSingleSelect
              v-else
              v-model="guardianId"
              title="GUARDIAN"
              label="full_name"
              :options="guardiansList"
              class="flex-1"
              :image="true"
              reduce="guardian_id"
              rules="required"
              :search-through-api="true"
              @search="getGuardians"
            />
            <InputBox
              v-model="relation"
              type="text"
              name="RELATION"
              title="RELATION"
              label="RELATION"
              placeholder="RELATION"
              class="flex-1"
              rules="required|alpha"
            />
          </InputFieldWrapper>
          <InputFieldWrapper class="mt-1">
            <ToggleButton v-model="isMainGuardian" heading="Main Guardian" :is-status-hide="true" />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Associate</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import { buildWhereQuery } from '@src/utils/filters/index.js'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    InputBox,
    ValidationObserver,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
    userType: { type: String, default: 'student' },
  },
  emits: ['toggle'],
  data() {
    return {
      studentList: [],
      guardiansList: [],
      studentId: '',
      guardianId: '',
      relation: '',
      isLoading: false,
      isMainGuardian: false,
    }
  },
  computed: {
    showStudents() {
      return this.userType === TENANT_ROLES.GUARDIAN
    },
  },
  created() {
    if (this.userType === 'student') {
      this.studentId = this.currentUser?.student_info?.id
      this.getGuardians()
    } else {
      this.guardianId = this.currentUser?.guardian_info?.id
      this.getStudents()
    }
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },

    getGuardians(query) {
      let studentCampuses = this.currentUser?.users_roles_entities?.map(
        (entity) => entity?.campus?.id,
      )
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
          ...buildWhereQuery(FILTER_KEYS.IN, 'campus_ids', studentCampuses),
        },
      }
      this.getGuardiansWithFilter(payload).then((res) => {
        this.guardiansList = res?.records
        this.guardiansList.forEach((guardian) => {
          guardian.guardian_id = guardian?.guardian_info?.id
        })
      })
    },

    getStudents(query) {
      let studentCampuses = this.currentUser?.users_roles_entities?.map(
        (entity) => entity?.campus?.id,
      )
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.IN, 'campus_ids', studentCampuses),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res.records
        this.studentList.forEach((record) => {
          record.student_id = record?.student_info?.id
        })
      })
    },

    async associateGuardian() {
      this.isLoading = true
      let payload = {
        student_info_id: this.studentId,
        guardian_info_id: this.guardianId,
        relation: this.relation,
        is_main_guardian: this.isMainGuardian,
      }
      this.associateStudentWithGuardian(payload)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('guardian', ['getGuardiansWithFilter']),
    ...mapActions('student', ['associateStudentWithGuardian', 'getStudentsWithFilters']),
  },
}
</script>
