<template>
  <ValidationObserver @submit="promoteStudent">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Student Promotion</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="promotionalData.campusId"
            title="CLSS"
            label="title"
            :options="classList"
            class="flex-1"
            reduce="id"
            rules="required"
            @change="setSection(promotionalData.campusId)"
            @receiveList="setSection"
          />
          <UiSingleSelect
            v-model="promotionalData.sectionId"
            title="SECTS"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            :options="sectionsList"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <ToggleButton
            v-model="promotionalData.isEnrollmetClosed"
            :required-items="1"
            heading="Close Enrollment ?"
            :is-status-hide="true"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Promote</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      classList: [],
      sectionsList: [],
      promotionalData: {
        campusId: '',
        sectionId: '',
        studentId: '',
        isEnrollmetClosed: false,
      },
    }
  },
  computed: {
    ...mapState('layout', ['campuses', 'isLoading']),
  },
  watch: {
    'promotionalData.campusId': {
      handler(val) {
        this.promotionalData.sectionId = ''
        this.sectionsList = []
        if (val) this.setSection(val)
      },
    },
  },
  created() {
    this.promotionalData.studentId = this.currentUser?.id
    this.getCampusClasses()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    setSection(id) {
      this.classList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsList = classItem.sections
      })
    },
    getCampusClasses() {
      this.getClassesList().then((res) => {
        this.classList = res.records
      })
    },
    async promoteStudent() {
      try {
        this.$store.commit('layout/IS_LOADING', true)

        let payload = {
          student_id: this.promotionalData.studentId,
          is_enrollment_closed: this.promotionalData.isEnrollmetClosed,
          promoted_section_id: this.promotionalData.sectionId,
        }
        removeEmptyKeysFromObject(payload)
        await this.studentPromotion(payload).then((response) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: 'Successfully Enrolled',
          })
        })
        this.$emit('toggle', true)
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('student', ['studentPromotion']),
    ...mapActions('classes', ['getClassesList']),
  },
}
</script>
