<template>
  <ValidationObserver>
    <TitleWrapper
      :filter-option="true"
      title="STUS"
      tooltip-title="STUS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
      @cancel="cancelHanlder"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.first_name"
            :class="$style.widthItems"
            class="flex-1"
            title="First Name"
            type="text"
            placeholder="SEARCH_BY_FNAME"
            rules="alpha_spaces"
            :filter="FILTERS_LIST.FIRST_NAME"
            @emitQuery="updateQuery"
          ></UiInput>
          <UiInput
            v-model.trim="filtersPayload.last_name"
            :class="$style.widthItems"
            class="flex-1"
            title="Last Name"
            type="text"
            placeholder="SEARCH_BY_LNAME"
            rules="alpha_spaces"
            :filter="FILTERS_LIST.LAST_NAME"
            @emitQuery="updateQuery"
          ></UiInput>
          <UiInput
            v-model.trim="filtersPayload.email"
            class="flex-1"
            title="Email"
            placeholder="Email"
            :filter="FILTERS_LIST.EMAIL"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.phone"
            title="Phone Number"
            placeholder="Phone Number"
            class="flex-1"
            :filter="FILTERS_LIST.PHONE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.registration_no"
            class="flex-1"
            title="Registration Number"
            type="text"
            placeholder="Registration Number"
            :filter="{
              option: FILTER_KEYS.ILIKE,
              key: 'registration_no',
            }"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.gender"
            title="Gender"
            :options="GENDERS"
            :class="$style.widthItems"
            input-color="bg-white"
            class="flex-1"
            label="title"
            reduce="value"
            :filter="FILTERS_LIST.GENDER"
            @emitQuery="updateQuery"
          />
          <UiDatePicker
            v-model="filtersPayload.date_of_birth_after"
            title="DOB_GT"
            :class="$style.widthItems"
            class="flex-1"
            :filter="FILTERS_LIST.DATE_OF_BIRTH_GREATER_THAN"
            @change="birthDateAfter"
            @emitQuery="updateQuery"
          />
          <UiDatePicker
            v-model="filtersPayload.date_of_birth_before"
            title="DOB_LT"
            :class="$style.widthItems"
            class="flex-1"
            :filter="FILTERS_LIST.DATE_OF_BIRTH_LESS_THAN"
            @change="birthDateBefore"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.address"
            class="flex-1"
            title="Address"
            type="text"
            placeholder="Address"
            :filter="FILTERS_LIST.ADDRESS"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.religion"
            class="flex-1"
            title="Religion"
            type="text"
            placeholder="Religion"
            :filter="FILTERS_LIST.RELIGION"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.login_enabled"
            title="STATUS"
            :options="LOGIN_ENABLED_STATUSES"
            :class="$style.widthItems"
            input-color="bg-white"
            class="flex-1"
            label="title"
            reduce="value"
            :filter="FILTERS_LIST.LOGIN_ENABLED_STATUS"
            @emitQuery="updateQuery"
          />
          <UiMultiSelect
            v-model.trim="filtersPayload.guardian_ids"
            title="Guardians"
            label="full_name"
            :options="guardiansList"
            class="flex-1"
            :image="true"
            reduce="guardian_id"
            input-color="bg-white"
            :already-selected="selectedGuardians"
            :search-through-api="true"
            :filter="FILTERS_LIST.GUARDIANS"
            @emitQuery="updateQuery"
            @search="getGuardians"
          />
          <UiSingleSelect
            v-if="!currentCampusScope"
            v-model.trim="filtersPayload.campus_id"
            title="CAMPUSES"
            label="title"
            :options="campuses"
            class="flex-1"
            :search-through-api="true"
            reduce="id"
            :filter="FILTERS_LIST.CAMPUS"
            @change="getCampusClasses"
            @emitQuery="updateQuery"
            @search="getCampus"
          />
          <UiSingleSelect
            v-if="!currentSectionScope"
            v-model.trim="filtersPayload.class_id"
            :search-through-api="true"
            :options="usersClassList"
            title="Class"
            label="title"
            class="flex-1"
            :disabled="!!currentClassScope"
            reduce="id"
            :filter="FILTERS_LIST.CLASS"
            @search="getCampusClasses"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-if="!currentSectionScope"
            v-model.trim="filtersPayload.section_id"
            title="SECTS"
            label="title"
            :search-through-api="true"
            class="flex-1"
            reduce="id"
            :options="usersSectionList"
            :filter="FILTERS_LIST.SECTION"
            @emitQuery="updateQuery"
            @search="getSection"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </ValidationObserver>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import fileMixins from '@/src/mixins/file-mixins'
import TitleWrapper from '@components/TitleWrapper.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { USER_CONSTANTS } from '@src/constants/general-constants'
import { formatObjectKeyValues, objectDeepCopy } from '@utils/generalUtil'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { removeEmptyKeysFromObject, getCountFromObject } from '@utils/generalUtil'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiMultiSelect,
    UiSingleSelect,
    TitleWrapper,
    UiDatePicker,
    ValidationObserver,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GENDERS: USER_CONSTANTS.GENDERS,
      LOGIN_ENABLED_STATUSES: USER_CONSTANTS.LOGIN_ENABLED_STATUSES,
      $where: {},
      queries: {},
      count: 0,
      FILTERS_LIST,
      forceRender: 0,
      guardiansList: [],
      selectedGuardians: [],
      filtersPayload: {
        first_name: '',
        last_name: '',
        gender: '',
        email: '',
        phone: '',
        registration_no: '',
        date_of_birth_before: '',
        date_of_birth_after: '',
        address: '',
        religion: '',
        campus_id: '',
        class_id: '',
        section_id: '',
        guardian_ids: [],
        login_enabled: '',
        displayedColumns: [],
      },
      FILTER_KEYS,
      filtersCount: 0,
      appliedQueries: {},
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
      currentClassScope: (state) => state.layout.currentClassScope,
    }),
    ...mapState({ usersClassList: (state) => state.classes.usersClassList }),
    ...mapState({ usersSectionList: (state) => state.section.usersSectionList }),
    changeCampus() {
      return this.filtersPayload.campus_id + this.currentCampusScope
    },

    ...mapState('layout', ['campuses']),
  },
  watch: {
    'filtersPayload.class_id': {
      handler(id) {
        this.filtersPayload.section_id = ''
      },
    },
    currentCampusScope: {
      handler() {
        this.clearFilters()
        this.getGuardians()
      },
    },
    changeCampus: {
      handler() {
        this.filtersPayload.class_id = ''
        this.filtersPayload.section_id = ''
        this.getCampusClasses()
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getGuardians()
      },
    },
  },
  created() {
    this.updateDisplayedColumnList()
  },
  mounted() {
    this.getGuardians()
    this.getCampusClasses()
  },
  methods: {
    getCampusClasses(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'campus_id',
            this.filtersPayload?.campus_id || this.currentCampusScope,
          ),
        },
      }

      removeEmptyKeysFromObject(payload)

      this.getUserClasses(payload).then((res) => {
        if (this.currentClassScope) {
          this.filtersPayload.class_id = this.currentClassScope?.id
          this.setSection(this.currentClassScope.id)
        }
      })
    },
    getSection(query = '') {
      this.setSection(this.filtersPayload?.class_id, query)
    },
    setSection(id = '', query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload)
    },

    getGuardians(query) {
      let alreadySelectedGuardians = []
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getGuardiansWithFilter(payload).then((res) => {
        this.guardiansList = res?.records
        this.guardiansList.forEach((guardian) => {
          fullName(guardian)
          guardian.guardian_id = guardian?.guardian_info?.id
          if (this.filtersPayload && this.filtersPayload.guardian_ids.length) {
            this.filtersPayload.guardian_ids.forEach((id) => {
              if (guardian.guardian_id === id) alreadySelectedGuardians.push(guardian)
            })
          }
          this.selectedGuardians = alreadySelectedGuardians
          this.forceRender++
        })
      })
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },

    getFiltersCount() {
      this.getGuardians()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },

    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('layout', ['getCampusList', 'getFilteredClassList']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('guardian', ['getGuardiansWithFilter']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
